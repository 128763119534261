let courseList = [
  "Python",
  "Java",
  "JavaScript",
  "Dart",
  "React",
  "Flutter",
  "Full-Stack",
  "SQL",
  "Front-End",
  "Others"
];
export { courseList };
