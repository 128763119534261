import React, { useEffect } from "react";
import { closeSideNavbar } from "../../Functions/SidebarFunction";
import "./Style/HomeLandingPage.scss";
import { SideNavbar } from "../../SideNavbar/SideNavbar";
// import { FloatingActionButton } from "../../FloatingActionButton/FloatingActionButton";
import { CoverImage } from "../CoverImage/CoverImage";
import { Journey } from "../Journey/Journey";
import { UpcomingCourses } from "../UpcomingCourses/UpcomingCourses";
import { StudentPlacement } from "../StudentPlacement/StudentPlacement";
import { Reviews } from "../../Home/Reviews/Reviews";
import { Clients } from "../Clients/Clients";
import { WhatsNewAtOcean } from "../WhatsNewAtOcean/WhatsNewAtOcean";
import { HowItWorks } from "../HowItWorks/HowItWorks";
import { Footer } from "../../Footer/Footer";
import { AppbarCombo } from "../../AppbarCombo/AppbarCombo";
// import TechFestBanner from "../TechFestBanner/TechFestBanner";

export function HomeLandingPage() {
  useEffect(() => {
    const doc = window.document;
    const appbar = doc.querySelector(".appbar > .tracking-line");

    const handleScroll = (e) => {
      var scrollPositionRatio =
        window.scrollY /
        (document.documentElement.scrollHeight - window.innerHeight);
      var accurateScrollPosition = Math.floor(scrollPositionRatio * 100); // Percentage-based scroll position

      // console.log("Accurate scroll position:", accurateScrollPosition + "%");

      appbar.style.width = `${accurateScrollPosition}%`;
    };

    doc.addEventListener("scroll", handleScroll);

    // Cleanup function to remove the event listener when the component is unmounted
    return () => {
      doc.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <AppbarCombo />
      <SideNavbar />
      {/* <FloatingActionButton /> */}

      <main className="HomeLandingPage" onClick={closeSideNavbar}>
        {/* <TechFestBanner />  */}
        <CoverImage />
        <Journey />
        <UpcomingCourses />
        <StudentPlacement />
        <Reviews />
        <Clients />
        <WhatsNewAtOcean />
        <HowItWorks />
      </main>
      <Footer />
    </>
  );
}
