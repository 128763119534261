import React, { useState } from "react";
import "./Style/OfflineDialogStyle.scss";
import offlineDialog from "../../../Image/offlineDialog .svg";
import { IoCloseOutline } from "react-icons/io5";
import { HiDownload } from "react-icons/hi";
import axios from "../../../../index";
import { countryCode } from "../../../CountryCode/CountryCode";
import { useRef } from "react";

export function OfflineDialog(props) {
  const emailPattan = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  const stringPattan = /^[A-Za-z]{3,30}(\s[A-Za-z]{3,30})*$/
  const mobileNumberPattern = /^\+?[1-9]\d{1,14}$/;

  const [userInfo, setUserInfo] = useState({
    name: "",
    countryCode: "+91", // Default country code
    mobileNumber: "",
    email: "",
  });

  const [validation, setValidation] = useState({
    name: true,
    mobileNumber: true,
    email: true,
  });

  const [phoneNumber, setPhoneNumber] = useState(""); // For the input field
  const [loading, setLoading] = useState(false);

  // Handle input changes
  const inputHandler = (e) => {
    const { name, value } = e.target;
    setUserInfo((prev) => ({ ...prev, [name]: value }));
    setValidation((prev) => ({ ...prev, [name]: true })); // Reset validation
  };

  const phoneNumberHandler = (e) => {
    const value = e.target.value.trim();
    setPhoneNumber(value);
    setUserInfo((prev) => ({ ...prev, mobileNumber: value })); // Sync with userInfo
    setValidation((prev) => ({ ...prev, mobileNumber: true })); // Reset validation
  };

  const finalValidation = () => {
    let isValid = true;

    // Validate name
    if (!stringPattan.test(userInfo.name.trim()) || /^\s/.test(userInfo.name)) {
      isValid = false;
      setValidation((prev) => ({ ...prev, name: false }));
    }

    // Validate email
    if (!emailPattan.test(userInfo.email.trim())) {
      setValidation((prev) => ({ ...prev, email: false }));
      isValid = false;
    }

    // Validate mobile number
    const fullMobileNumber =
      `${userInfo.countryCode}${userInfo.mobileNumber}`.replace(/\s/g, "");
    console.log("Full Mobile Number:", fullMobileNumber); // Debugging

    // If mobile number is empty or invalid, set validation to false
    if (
      !userInfo.mobileNumber.trim() ||
      !mobileNumberPattern.test(fullMobileNumber)
    ) {
      setValidation((prev) => ({ ...prev, mobileNumber: false }));
      isValid = false;
    }

    return isValid;
  };

  const submit = () => {
    if (!finalValidation()) {
      console.log("Invalid form data");
    } else {
      setLoading(true);
      axios
        .post(
          `/downloaded/course/add/user/${props.course_id}`,
          {
            ...userInfo,
            courseId: props.courseId,
          },
          { responseType: "blob" }
        )
        .then((res) => {
          setLoading(false);
          console.log(res.data);
          setUserInfo({
            name: "",
            countryCode: "+91",
            mobileNumber: "",
            email: "",
          });
          if (res.status == 200) {
            const blob = new Blob([res.data], { type: "application/pdf" }); // Create Blob
            const link = document.createElement("a");
            link.href = URL.createObjectURL(blob); // Create object URL from Blob
            link.setAttribute(
              "download",
              `${`${props.courseId}-syllabus` || "syllabus"}.pdf`
            ); // Set the download attribute
            document.body.appendChild(link); // Append link to the DOM (for Firefox compatibility)
            link.click(); // Programmatically click the link
            link.remove(); // Remove the link from the DOM
          } else {
            alert("File not found!");
          }
          setPhoneNumber(""); // Clear the phone number input
          closeDialog();
        })
        .catch((error) => {
          setLoading(false);
          console.log(error);
        });
    }
  };

  const closeDialog = () => {
    document.querySelector(".offlineDialog").style.display = "none";
  };

  return (
    <div className="offlineDialog">
      <div className="card">
        <div className="closeBtn">
          <p>Download PDF</p>
          <IoCloseOutline className="closeIcon" onClick={closeDialog} />
        </div>
        <div className="row">
          <img src={offlineDialog} alt="Offline Dialog Illustration" />

          <div className="col">
            <input
              type="text"
              placeholder="Name"
              name="name"
              value={userInfo.name}
              required
              onChange={inputHandler}
            />
             {!validation.name && (
        <span style={{ color: "red" }}>
          {userInfo.name.trim() === "" ? "Name is required" : "Incorrect Name"}
        </span>
      )}

            <div className="login-Card-Top-Textfield">
              <select
                name="countryCode"
                value={userInfo.countryCode}
                onChange={inputHandler}
                className="country-Dropdown"
              >
                <option value="+91">+91 IN</option>
                {countryCode.map((data, index) => (
                  <option key={index} value={data.dial_code}>
                    {`${data.dial_code} ${data.code}`}
                  </option>
                ))}
              </select>
              <input
                type="number"
                placeholder="Mobile Number"
                value={phoneNumber}
                onChange={phoneNumberHandler}
                className="mobileInput"
              />
            </div>
            {!validation.mobileNumber && (
        <span style={{ color: "red" }}>
          {phoneNumber.trim().length < 10 ? "Mobile number is required" : "Incorrect Mobile Number"}
        </span>
      )}

            <input
              type="text"
              placeholder="Email"
              name="email"
              value={userInfo.email}
              required
              onChange={inputHandler}
            />
            {!validation.email && (
        <span style={{ color: "red" }}>
          {userInfo.email.trim() === "" ? "Email is required" : "Incorrect Email"}
        </span>
      )}
            <br />
            <div className="btn"
            style={{
              backgroundColor: loading ? '#E5E7EB' : '', // Full button background
              cursor: loading ? 'not-allowed' : 'pointer', // Disable pointer cursor while loading
            }}>
            
              
              {loading ? (
                  <></>
                ) : (
                  <>
                   <div className="left"></div>
              <div className="right"></div>
              <div className="top"></div>
              <div className="bottom"></div>
                  </>
                )}
              <div className="btnContent" 
              style={{
                backgroundColor: loading ? '#E5E7EB' : '',
                // color: loading ? '#E5E7EB' : '', // text-gray-200 equivalent
                
              }}
              onClick={!loading ? submit : null}  
              disabled={loading}  >
                {loading ? (
                  <p>Downloading...</p>
                ) : (
                  <>
                    <HiDownload className="icon" />
                    <p>Download Pdf</p>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
